import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { dynamicSort } from './function';
import { deleteCompetence, getCompany, getDetail, getListPersonnel, updateCompetence } from './api/api';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';
import { DatePicker } from '@mui/x-date-pickers';
import { AttestationPdf } from './printAttestation';
import { Attestation } from './printing';

const WorkerDetails = () => {



  const { id } = useParams();
  const [isAdmin,setIsadmin] = useState(sessionStorage.getItem("adminLogged"))
  const [worker, setWorker] = useState(null);
  const [formations, setFormations] = useState([]);
  const [historique,setHistorique]=useState(false)
  const [formationSelect,setformationSelect]=useState({})
  const [modificationFormation,setModificationFormation]=useState(null)
  const [printAttestation,setPrintAttestation]=useState(false)
  const [company,setCompany]=useState({})
  const navigate=useNavigate()

  const deleteFormation = ()=>{
    deleteCompetence(formationSelect.id).then(e=>{
      setformationSelect({})
     
    }).catch(e=>{})
  }


  const fetchWorkerData = async () => {
    try {
    
      const response = await getDetail(id,isAdmin) 
      
      
      if(response.worker){
      setWorker(response.worker);
      try{
        let _formation =response.training//.sort(dynamicSort('name'))
        setFormations(_formation)
      }
      catch{
      setFormations(response.formations);
      }
    }

    } catch (error) {
      setWorker(-1)
      setFormations(-1)
      console.error("There was an error fetching the worker data!", error);
    }
  };
  useEffect(()=>{

    if(worker?.idcompany && !company?.id){
        getCompany(worker.idcompany).then( data=>{
      
         setCompany(data[0])
        }
        )
    }

  },[worker])
  useEffect(()=>{
    try{
        setCompany(JSON.parse(sessionStorage.getItem("company")))
    }
    catch{}
  },[])

    useEffect(() => {
    fetchWorkerData();
  }, [id,formationSelect,modificationFormation]);
  const handleSave=()=>{
  
    if(modificationFormation.newDate){
      updateCompetence({id:modificationFormation.id,newDate:modificationFormation.newDate}).finally(e=>{setModificationFormation({});fetchWorkerData()})
    }
  }
  if(worker ===-1){
    return <div>Worker not found</div>;
  }
  if (!worker) {
    return <div>Loading...</div>;
  }
  
  var all= true
  
  return (
    
    <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',flex:1,minWidth:'100%'}}>
        <Modal open={printAttestation?true:false}>
        {
          <Attestation personnels={[worker]} callBack={setPrintAttestation} competence={[]} />
        }
        </Modal>
        <Button onClick={()=>navigate("/workers")} style={{display:isAdmin?'':'none',position:'absolute',top:20,left:20}}>Retour à la liste</Button>
        <Dialog open={formationSelect.name?true:false} sx={{margin:20}}>
          <Box sx={{padding:4}}>    
          <DialogTitle>Suppression </DialogTitle>
          <DialogContentText>Confirmez vous la suppression de la formation {formationSelect.name} </DialogContentText>
          <DialogActions sx={{paddingTop:3}}><Button onClick={()=>{deleteFormation()}}>Oui</Button><Button onClick={()=>{setformationSelect({})}}>Non</Button></DialogActions>
          </Box>
        </Dialog>
       
      
        <img style={{height:150}} src={company?.logo}/>
      <h1 style={{paddingLeft:10}}>{worker.denomination}</h1>
      <Button style={{display:isAdmin?'':'none'}} onClick={()=>setPrintAttestation(true)}>Imprimer le document sécurité</Button>
      <Button onClick={()=>{setHistorique(!historique)}}>{historique?"Masquer l'historique":"Afficher l'historique"}</Button>
      <h2>Compétences :</h2>
      <div style={{display:"flex",minWidth:'100%',justifyContent:'center'}}>
      {/* Table pour les formations à venir */}
        <table style={{ borderCollapse: 'collapse',borderRadius:15, minWidth: '90%',padding:15 }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: 8, backgroundColor:'lightgrey' }}>{formations.length>1?"COMPETENCES":"COMPETENCE"}</th>
              <th style={{ border: '1px solid black', padding: 8, backgroundColor:'lightgrey' }}>{formations.length>1?"DATES DE VALIDITE":"DATE DE VALIDITE"}</th>
              {isAdmin &&(
                <th style={{ border: '1px solid black',maxWidth:300, padding: 8, backgroundColor:'lightgrey' }}>Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {formations.map((formation, index) => (
              moment(formation.dateValidity).isAfter(moment()) ? (
                <tr key={index}>
                  <td style={{ border: '1px solid black', padding: 8 }}>
                    {formation.name}
                  </td>
                  {modificationFormation?.id===formation.id?
                  <td style={{textAlign:'center' ,border: '1px solid black', padding: 8 }}>
                  <DatePicker onChange={(e)=>{setModificationFormation({...modificationFormation,newDate:e})}} value={moment(formation.dateValidity)}></DatePicker>
                  <Button onClick={()=>handleSave()}>Sauver</Button>
                </td>
                
                  :
                  <td style={{textAlign:'center' ,border: '1px solid black', padding: 8 }}>
                    <a style={{cursor:"pointer"}} onClick={()=>setModificationFormation(formation)}>{moment(formation.dateValidity).format("DD/MM/YYYY")}</a>
                  </td>
                  
                  }
                   {isAdmin &&(
                    <td style={{textAlign:'center',maxWidth:300 ,border: '1px solid black', padding: 4 }}>
                      <Button onClick={()=>setformationSelect(formation)}>Supprimer</Button>
                    </td>
                  )}
                </tr>
              ) : null
            ))}
          </tbody>
        </table>
      </div>
      <div style={{display:historique?'flex':'none',flex:1,minWidth:'90%',justifyContent:'center',flexDirection:'column'}}>
        <h2 style={{paddingLeft:10}}>Expirées :</h2>
        <table style={{ borderCollapse: 'collapse',borderRadius:15, minWidth: '90%',padding:15 }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: 8, backgroundColor:'lightgrey' }}>{formations.length>1?"COMPETETENCES":"COMPETENCE"}</th>
              <th style={{ border: '1px solid black', padding: 8, backgroundColor:'lightgrey' }}>{formations.length>1?"DATES DE VALIDITE":"DATE DE VALIDITE"}</th>
              {isAdmin &&(
                <th style={{ border: '1px solid black',maxWidth:300, padding: 8, backgroundColor:'lightgrey' }}>Action</th>
              )}
            </tr>
          </thead>
          <tbody>
          {formations.map((formation, index) => (
            moment(formation.dateValidity)<moment()?
            <tr key={index}>
                  <td style={{ border: '1px solid black', padding: 8 }}>
                    {formation.name}
                  </td>
                  {modificationFormation?.id===formation.id?
                  <td style={{textAlign:'center' ,border: '1px solid black', padding: 8 }}>
                  <DatePicker onChange={(e)=>{setModificationFormation({...modificationFormation,newDate:e})}} value={moment(formation.dateValidity)}></DatePicker>
                  <Button onClick={()=>handleSave()}>Sauver</Button>
                </td>
                
                  :
                  <td style={{textAlign:'center' ,border: '1px solid black', padding: 8 }}>
                    <a style={{cursor:"pointer"}} onClick={()=>setModificationFormation(formation)}>{moment(formation.dateValidity).format("DD/MM/YYYY")}</a>
                  </td>
                  
                  }
                  {isAdmin&&(
                  <td style={{textAlign:'center',maxWidth:300 ,border: '1px solid black', padding: 4 }}>
                    <Button onClick={()=>setformationSelect(formation)}>Supprimer</Button>
                  </td>
                  )}
                </tr>
              :null))}
             
          </tbody>
        </table>
      </div>
    </div>
  );
};

const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default WorkerDetails;
