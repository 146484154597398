import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useRouteLoaderData } from 'react-router-dom';

import moment from 'moment';
import { dynamicSort } from './function';
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import { PdfQrCode } from './qrCode';
import { fetchQr, getCompany, getCompanyData, getListPersonnel, trainingUpdate, verifyAuth, workerAdd, workerDelete, workerUpdate } from './api/api';
import { Alert, Box, Button, FormLabel, Input, Modal, Snackbar, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid/';
import {frFR} from '@mui/x-data-grid/locales'

import { AttestationPdf } from './printAttestation';
import { Attestation } from './printing';
import md5 from 'react-native-md5';
import { type } from '@testing-library/user-event/dist/type';
import { ResetPassword } from './auth/resetPassword';

const adminPassword  = "#MdpAdminTest#"





const WorkerList = () => {
  const [snackbar, setSnackbar] = useState(null);
  const [deleteWorker,setDeleteWorker]=useState(false)

  const { id } = useParams();
  const [worker, setWorker] = useState(null);
  const [adminMode,setAdminMode]=useState(false)
  const [superAdmin,setSuperAdmin]=useState(true)
  const [fly,setFly]=useState(null)
  const [printQr,setPrintQr]=useState(null)
  const navigate=useNavigate()
  const [company,setCompany]=useState({selected:null,avaiable:[]})
  const dataGridSelect=useRef()
  const [printAttestation,setPrintAttestation]=useState(false)
  const [showOut,setShowOut]=useState(false)
  const [auth,setAuth]=useState({mail:null,password:null})
  const [updatePassword,setUpdatePassword]=useState(false)
  const [createWorker,setCreateWorker]=useState({new:false,data:{active:true}})
  const columns=[
    {field:'name',headerName:'Nom',flex:2,editable:true},
    {field:'firstname',headerName:'Prénom',flex:2,editable:true},
    {field:'mail',headerName:'Email',flex:2,editable:true,editable:true},
    {field:'active',headerName:'Actif',flex:1,editable:true,type:'boolean'},
    {field:'uid',headerName:'Détails',renderCell:(field)=>(
    
      <Button onClick={()=>navigate("../workers/"+field.value)}>Détail</Button>
    )},
      {field:'delete',headerName:'Supprimer',renderCell:(field)=>(
        <Button onClick={()=>{setDeleteWorker(field.row);console.log(field.row)}}>Supprimer </Button>
       
    )
  }
 ]
  const proccessRowUpdate=useCallback(
    async(newRow)=>{
       try{
        delete newRow.denomination
        delete newRow.uid
       }
       catch{}
        const response=await  workerUpdate(newRow);
        setSnackbar({children:`L'utilisateur à été mis à jour`,severity:'success'})
        return(newRow)
    },
    
)




  const navigateWithParams=()=>{
    navigate("../new",{state:{selected:dataGridSelect.current.getSelectedRows()}})
  }

  const verifAdmin =()=>{
    let pwdA = auth.password
    let mail=auth.mail
 
    pwdA=md5.hex_md5(pwdA)
    verifyAuth({password:pwdA,mail:mail}).then(data=>{
      sessionStorage.setItem("adminLogged","1")
      sessionStorage.setItem("company",JSON.stringify(company.selected))
      sessionStorage.setItem("user",JSON.stringify(data))
      if(data?.superadmin===true){
          sessionStorage.setItem("superAdmin","1")
      }

  
      sessionStorage.setItem("idUser",data.mail)
      setTimeout(()=>setAdminMode(true),500)
     }
    ).catch(e=>setSnackbar({children:'Verifiez vos informations de connexion',severity:'error'}))
    
  }

  useEffect(()=>{
    var selected
    try{
      selected=JSON.parse(sessionStorage.getItem("company"))
    }
    catch{
      selected={}
    }
    getCompany().then(data=>setCompany({selected:selected,avaiable:data}))
    if(sessionStorage.getItem("adminLogged")){

      setAdminMode(true)
    }
  },[])
  useEffect(() => {

    fetchWorkerData();
  }, [adminMode,showOut]);
  const fetchWorkerData = async () => {
    try {
    
      //console.log(`${window.location.protocol}//${window.location.hostname}/api/workers`)
      const response = await getListPersonnel(showOut) 
    
      if(response){
        let _worker = response.rows
       
        try{
          _worker.sort(dynamicSort("denomination"))
        }
        catch{
          
        }
      setWorker(_worker);
     
      }

    } catch (error) {
      setWorker(-1)
 
      console.error("There was an error fetching the worker data!", error);
    }
  };
  if(worker ===-1 && adminMode){
    return <div>Worker not found</div>;
  }
  if (!worker && adminMode) {
    return <div>Loading...</div>;
  }

 
  const generateQr =()=>{
    let list =[] 
    setPrintQr(true)
    try{
      let rows =dataGridSelect.current.getSelectedRows()
      
      rows.forEach((work)=>{
     
          list.push(work.id)
      })
     }
     catch(e){
      console.log(e)
     }
    
     if(list.length>0){
       setPrintQr(list)
      
     }
     
  }

  const printSecurityDoc=()=>{
    let rows =dataGridSelect.current.getSelectedRows()
    var rowsSend=[]
     rows.forEach(e=>rowsSend.push(e))
     setPrintAttestation(rowsSend)
  }

const changeCallBack=(data)=>{
 
  if(data===false){
    setUpdatePassword(false)
    setSnackbar({children:'La modification a été annulée',severity:'warning'})
    return
  }
  if(data?.error){
    setSnackbar({children:`Impossible de modifier le mot de passe, vérifiez l'ancien mot de passe`,severity:'error'})
    return
  }
  else{
    setSnackbar({children:'Le mot de passe a été modifié',severity:'success'})
    setUpdatePassword(false)
  }
}

 const handleKeyPress=(event)=>{
  
  if(event.key==="Enter"){
    if (company.selected?.id){
      verifAdmin()}
    else{
      setSnackbar({children:`Vous n'avez pas choissis de société`,severity:'error'})}
  }
 }

  return (
    
    <div onKeyDown={handleKeyPress} style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',flex:1,minWidth:'100%'}}>
       <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbar===null?false:true} children={snackbar}al onClose={()=>setSnackbar(null)} autoHideDuration={6000} >
            <Alert {...snackbar} onClose={()=>setSnackbar(null)} />
          </Snackbar>
      <Modal open={deleteWorker?true:false}>
        <Box style={{display:'flex',minHeight:'100vh',justifyContent:'center',alignItem:'center',flexDirection:'column'}}>
            
            <Box style={{display:'flex',flexDirection:'column',width:'50%',alignSelf:'center',backgroundColor:'white',padding:10,borderRadius:10}}>
            <Typography style={{alignSelf:'center'}}>Confirmer la suppression de {deleteWorker?.denomination} </Typography>
           <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between',minWidth:'100%'}}>
            <Button onClick={()=>{setDeleteWorker(null)}}>Non</Button>
            <Button onClick={()=>workerDelete(deleteWorker?.id).then(e=>fetchWorkerData()).finally(setDeleteWorker(null))}>Oui</Button>
            </div>
        </Box>
        </Box>
      </Modal>
      <Modal open={updatePassword}>
          <ResetPassword callBack={changeCallBack}/>
      </Modal>
      <Modal open={printAttestation?true:printQr?true:false}>
        {
      printAttestation?
        <Attestation personnels={printAttestation} callBack={setPrintAttestation} competence={[]} />
      :
     
     // <div style={{display:'flex',flexDirection:'columns',alignItems:'center',justifyContent:'center',flex:1,minWidth:'100%'}}>
          <PdfQrCode list={printQr} callBack={setPrintQr} /> 
     // </div>
        }
      </Modal>
          {fly &&
          <div style={{position:'absolute',left:'20%',top:fly.posY}}>
             
          </div>
          }
       
      
      
     
      {!adminMode && (
        <div  style={{display:'flex',height:'100vh',flexDirection:'column',width:'100%',justifyContent:'center',alignItems:'center'}} >
          
         
          <div>
          {company.avaiable.map(cmp=>
            <img  onClick={()=>setCompany({...company,selected:cmp})} style={{ cursor:'pointer',height:70,padding:20,filter: cmp.id===company.selected?.id?"":'grayscale(100%)' }} src={cmp.logo}/>
          )}
           </div>
           <Input placeholder='Email' onChange={(e)=>{setAuth({...auth,mail:e.target.value})}} type='email' id ='email'/>
           <Input placeholder='Password' type='password' onChange={(e)=>{setAuth({...auth,password:e.target.value})}} id='pwd'/> 
           <Button accessKey='Enter' onKeyDown={handleKeyPress} onClick={()=>{
            if (company.selected?.id){
              verifAdmin()}
            else{
              setSnackbar({children:`Vous n'avez pas choissis de société`,severity:'error'})}}}>Connexion</Button>
        </div>
      )}
    
      {adminMode && (
        
        <div style={{display:'flex',flexDirection:'column'}}>
        <img style={{height:150,objectFit:'contain'}}  src={company.selected?.logo}/>
        <div style={{display:'flex',minWidth:'100%',justifyContent:'space-between',flexDirection:'row-reverse'}}>
        <Button style={{maxWidth:200,alignSelf:'center'}} onClick={()=>{setAdminMode(false);sessionStorage.clear();setCompany({...company,selected:{}})}}>Déconnexion</Button>
        
        <Button style={{display:sessionStorage.getItem('superAdmin')==="1"?'':'none',maxWidth:200,alignSelf:'center'}} onClick={()=>{navigate("/history")}}>Historique</Button>
        
        <Button style={{maxWidth:300,alignSelf:'center'}} onClick={()=>{setUpdatePassword(true)}}>Modification du mot de passe</Button>
        </div>
        <div style={{flexDirection:'row'}}>
        <Button onClick={()=>setShowOut(!showOut)}>{showOut===true?"Masquer les personnes sorties":"Afficher les personnes sorties"}</Button>
          <Button onClick={()=>navigateWithParams()}>Ajouter des compétences</Button>
          <Button onClick={()=>navigate("../training")}>Gerer les formations</Button>
          <Button onClick={()=>generateQr()}>Générer les codes QR</Button>
          <Button onClick={()=>printSecurityDoc()}>Générer les documents de sécurité</Button>
        </div>
          
        </div>
      )}

      {adminMode &&(
        <Box style={{height:'70vh',maxHeight:'70vh',minWidth:'50%'}}>
         
          <DataGrid
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          autoPageSize  
          apiRef={dataGridSelect}
        
          pagination
            rows={worker}
            processRowUpdate={proccessRowUpdate}
            
            columns={columns}
            checkboxSelection
            disableRowSelectionOnClick
            //onRowClick={(e)=>navigate(`../workers/${e.row.uid}`)}
            
          />
          <Button style={{display:createWorker.new?'none':''}} onClick={()=>setCreateWorker({...createWorker,new:true})}>Nouveau</Button>
          {createWorker.new&&(
                createWorker.new && (
                  <Box style={{padding:10}}>
                  <FormLabel>Création</FormLabel>
                      <Box>
                      <Input
                          placeholder='Nom'
                          style={{marginLeft:10,marginRight:10,width:500}}
                          value={createWorker.data.name}
                          onChange={e=>setCreateWorker({...createWorker,data:{...createWorker.data,name:e.target.value}})}
                      ></Input>
                      <Input
                          placeholder='Prénom'
                          style={{marginLeft:10,marginRight:10}}
                          value={createWorker.data.firstname}
                          onChange={e=>setCreateWorker({...createWorker,data:{...createWorker.data,firstname:e.target.value}})}
                      ></Input>
                        <Input
                          placeholder='Email'
                          
                          style={{marginLeft:10,marginRight:10}}
                          
                          value={createWorker.data.mail}
                          
                          onChange={e=>{
                              
                                      setCreateWorker({...createWorker,data:{...createWorker.data,mail:e.target.value}})
                                  }
                             
                          }
                          
                      ></Input>
                      <Button onClick={()=>{workerAdd({...createWorker.data,idCompany:JSON.parse(sessionStorage.getItem("company")).id}).
                          then(e=>{fetchWorkerData();setCreateWorker({new:false,data:{active:true}});setSnackbar({children:'Travailleur ajouté',severity:'success'})})
                          .catch()}} >Sauver</Button>
                      <Button onClick={()=>setCreateWorker({new:false,data:{active:true}})} >Annuler</Button>
                      </Box>
                  </Box>
              )
          )}
        </Box>
      // <ul>
      //   {worker.map((worker, index) => (
      //     worker.denomination?
      //     <li key={index}>
            
      //         <input key={"qr"+worker.uid} id={`qr${worker.uid}`} type="checkbox"></input>
      //       <a onMouseOver={(e)=>setFly({id:worker.uid,posY:e.clientX})} onMouseOut={()=>setFly(null)} href={`../workers/${worker.uid}`}>{`${worker.denomination}`}</a>
      //     </li>
      //    :<></>
      //   ))}
      // </ul>
    )}

    </div>
  );
};





export default WorkerList;
