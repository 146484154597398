import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
import WorkerDetails from './WorkerDetails';
import WorkerList from './WorkerList';
import { NewCompetence } from './createCompetence';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from'@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/fr'
import { TrainingList } from './training';
import { History } from './history';

function App() {
  useEffect(()=>{
    return(()=>{
      localStorage.clear();
    })
  },[])
  return (
    
    <LocalizationProvider adapterLocale='fr-fr' dateAdapter={AdapterMoment}>
    
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/workers" replace />} />
          <Route path='history/' element={<History/>}/>
          <Route path="workers/:id" element={<WorkerDetails />} />
          <Route path="workers/" element={<WorkerList/>} />
          <Route path="new/" element={<NewCompetence/>} />
          <Route path='training/' element={<TrainingList/>} />
        </Routes>
      </div>
    </Router>
    </LocalizationProvider>
  );
}

export default App;
